<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/stationarylamps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/124.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD SCISSORS
 
 


                </h4>
                <h5 style="text-align: center">SINT-160</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/stationarylamps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/125.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD STAPLER




                </h4>
                <h5 style="text-align: center">SINT-165</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/stationarylamps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/126.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD MAGNIFER LAMP
 
                  
 


                </h4>
                <h5 style="text-align: center">SINT-509</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/stationarylamps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/127.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 ESD MAGNIFIER LAMP LED TYPE
 
                  
                 
 


                </h4>
                <h5 style="text-align: center">SINT-509-LED
                </h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
        <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/stationarylamps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/128.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                ESD PUNCHER

 


                </h4>
                <h5 style="text-align: center">SINT-169</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/stationarylamps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/129.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 ESD PEN HOLDER




                </h4>
                <h5 style="text-align: center">SINT-173</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/stationarylamps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/130.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD BALL PEN 
                  
 


                </h4>
                <h5 style="text-align: center">SINT-176</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/stationarylamps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/131.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 ESD ROUND WASTE BIN
 
                  
                 
 


                </h4>
                <h5 style="text-align: center">SINT-177</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
        <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/stationarylamps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/132.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD RECTANGLE WASTE BINS
 
 


                </h4>
                <h5 style="text-align: center">SINT-178</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/stationarylamps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/133.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD KEYBOARD




                </h4>
                <h5 style="text-align: center">SINT-180</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/stationarylamps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/134.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD MOUSE
                  
 


                </h4>
                <h5 style="text-align: center">SINT-181</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/stationarylamps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 50% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/135.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 ESD WATCH
 
                  
                 
 


                </h4>
                <h5 style="text-align: center">SINT-191</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
    
     
    
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>